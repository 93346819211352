import React, { useContext, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import "./hostlisting.css";
import DemoContext from "context/DemoContext";
import moment from "moment";
import { Trash3 } from "react-bootstrap-icons";
import AuthContext from "context/AuthContext";
import ProfileContext from "context/ProfileContext";
import CustomModal from "components/Modal/CustomModal";
import { BlankImg } from "assets/images";
import ImgComponent from "components/imgComponent";

const FallbackHostList = () => {
  const {
    listFallbackHosts,
    fallbackHostPrevious,
    fallbackHostNext,
    setFallbackHost,
    deleteFallbackHost,
    getAllFallbackHost,
    allhosts
  } = useContext(DemoContext);
  let { userProfile, user } = useContext(AuthContext);
  let { updatedHostsOnce } = useContext(ProfileContext);
  const [loadingPros, setLoadingPros] = useState(false);
  const [showAddFallback, setShowAddFallback] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showDeleteSucess, setShowDeleteSucess] = useState(false);
  const [selectedHostId, setSelectedHostId] = useState("");

  const handleClose = () => {
    setShowAddFallback(false);
  };
  const handleCloseError = () => {
    setShowError(false);
  };
  const handleCloseDeleteSucess = () => {
    setShowDeleteSucess(false);
  };
  const addNewFallbackHost = async (host_id) => {
    setLoadingPros(true);
    let store_identifier = userProfile?.store?.store_id;
    let payload = {
      store_identifier: store_identifier,
      host: host_id,
    };
    try {
      let res = await setFallbackHost(payload);
      if (res.status === 201) {
        getAllFallbackHost(`?order=true`);
        setShowAddFallback(false);
        setLoadingPros(false);
      } else {
        setShowAddFallback(false);
        setLoadingPros(false);
        setShowError(true);
      }
    } catch (err) {
      setLoadingPros(false);
      setShowAddFallback(false);
      setShowError(true);
    }
  };

  const fallbackHostDelete = async (id) => {
    setLoadingPros(true);
    try {
      let res = await deleteFallbackHost(id);
      if (res.status === 204) {
        getAllFallbackHost(`?order=true`);
        setLoadingPros(false);
        setShowDeleteSucess(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getFallbackHosts = () => {
    if (loadingPros) {
      return (
        <tr>
          <td>
            <div className="d-flex justify-content-center">
              <Spinner animation="grow" />
              <Spinner animation="grow" />
              <Spinner animation="grow" />
            </div>
          </td>
        </tr>
      );
    } else {
      if (listFallbackHosts) {
        if (listFallbackHosts?.length < 1) {
          return (
            <tr>
              <td>There aren't any fallback hosts yet!</td>
            </tr>
          );
        } else {
          return listFallbackHosts?.map((listFallbackHost, index) => {
            return (
              <tr key={index}>
                <td className="image-title-wrapper">
                  <ImgComponent
                    className="host-pp-small"
                    src={
                      listFallbackHost?.host?.profile_picture?.file !== null
                        ? listFallbackHost?.host?.profile_picture?.file
                        : BlankImg
                    }
                    alt="user"
                  />
                  {listFallbackHost?.host?.first_name}{" "}
                  {listFallbackHost?.host?.last_name}
                </td>
                <td>
                  {moment(listFallbackHost?.created_at).format("YYYY-MM-DD")}
                </td>
                <td>{listFallbackHost?.host?.email}</td>
                <td>
                  <button
                    className="btn"
                    style={{ background: "transparent" }}
                    onClick={() => {
                      fallbackHostDelete(listFallbackHost?.id);
                    }}
                    disabled={user.role === 5}
                  >
                    <Trash3 style={{ fontSize: "20px", color: "red" }} />
                  </button>
                </td>
              </tr>
            );
          });
        }
      } else {
        return (
          <tr>
            <td>There aren't any fallback hosts yet!</td>
          </tr>
        );
      }
    }
  };

  return (
    <div>
      <div className="info-fallbackHost">
        <div className="text-end">
          <div
            className=" btn btn-primary bg-secondary m-3 "
            onClick={() => {
              setShowAddFallback(true);
            }}
            hidden={user.role === 5}
          >
            + Add Fallback Host
          </div>
        </div>
      </div>
      <CustomModal
        showStatus={showAddFallback}
        clickStatus={handleClose}
        buttonMessage="Close"
        closeStatus={handleClose}
        title="Add Fallback Host"
        message={
          <HostLists
            updatedHostsOnce={allhosts}
            selectedHostId={selectedHostId}
            setSelectedHostId={setSelectedHostId}
            addNewFallbackHost={addNewFallbackHost}
          />
        }
        secondButton={false}
        secondMessage="none"
      />
      <CustomModal
        showStatus={showError}
        clickStatus={handleCloseError}
        buttonMessage="Close"
        closeStatus={handleCloseError}
        title="User Already Exist"
        message={"This User Already exist in fallback host list."}
        secondButton={false}
        secondMessage="none"
      />
      <CustomModal
        showStatus={showDeleteSucess}
        clickStatus={handleCloseDeleteSucess}
        buttonMessage="Close"
        closeStatus={handleCloseDeleteSucess}
        title="Deleted Sucessfully"
        message={"User deletion successful."}
        secondButton={false}
        secondMessage="none"
      />
      <Table className="table-store" responsive>
        <thead>
          <tr>
            <th scope="col">
              <div className="arrows">Host</div>
            </th>
            <th scope="col">
              <div className="arrows">Created At </div>
            </th>
            <th scope="col">
              <div className="arrows">Email</div>
            </th>
            <th scope="col">
              <div className="arrows">Action</div>
            </th>
          </tr>
        </thead>
        <tbody>{getFallbackHosts()}</tbody>
      </Table>
      {(fallbackHostNext !== null || fallbackHostPrevious !== null) && (
        <div className="button-wrapper-product text-center my-4">
          <button
            onClick={() => {
              setLoadingPros(true);
              let res = getFallbackHosts(new URL(fallbackHostPrevious).search);
              res.then(() => setLoadingPros(false));
            }}
            className="btn btn-outline-primary previous-button"
            disabled={fallbackHostPrevious === null}
          >
            Prev
          </button>
          <button
            onClick={() => {
              setLoadingPros(true);
              let res = getFallbackHosts(new URL(fallbackHostNext).search);
              res.then(() => setLoadingPros(false));
            }}
            className="btn btn-outline-primary previous-button"
            disabled={fallbackHostNext === null}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default FallbackHostList;

export const HostLists = ({
  updatedHostsOnce,
  selectedHostId,
  setSelectedHostId,
  addNewFallbackHost,
}) => {
  return (
    <div>
      <h5>Choose Your Fallback Host</h5>
      <select
        className="form-select-lg"
        name="selectHost"
        defaultValue=""
        style={{ width: "100%" }}
        onChange={(e) => setSelectedHostId(e.target.value)}
      >
        <option value="" disabled>
          Select Host
        </option>
        {updatedHostsOnce.map((res) => {
          return (
            <option value={res?.user?.id} key={res?.user?.id}>
              {res?.user?.first_name} {res?.user?.last_name}
            </option>
          );
        })}
      </select>
      <div className="text-end">
        <button
          className="btn btn-primary p-2 m-1"
          onClick={() => {
            addNewFallbackHost(selectedHostId);
          }}
          disabled={!selectedHostId}
        >
          Add
        </button>
      </div>
    </div>
  );
};
