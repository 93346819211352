import React from "react";
import { Button } from "react-bootstrap";
import "./Landing.css";
import Cardcomponent from "./Cardcomponent";
import Jumping from "./Jumping";
import { Grad1 } from "../../assets/images";
import { useNavigate } from "react-router-dom";

const Landing = () => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        {/* first section */}
        <div className="container first-p">
          <div className="row">
            <div className="leftone col-md-12 col-lg-6">
              <div className="el-1">
                <h1>
                  Get rewards for pure and honest feedback on products you already use
                </h1>
              </div>
              <div className="home-para">
                <p class="landing-para">
                  That's right! Purebrand connects you with potential customers
                  to answer questions, give live demos, and share honest
                  feedback of the products you already own and are passionate
                  about. In turn we reward you for doing so.
                </p>
              </div>
              <div className="el-1">
                <h3>Get started today</h3>
              </div>
              <div className="el-1">
                <Button onClick={() => navigate("/signup")} variant="primary">
                  Sign up
                </Button>{" "}
              </div>
              <div className="el-1">
                <p className="assistance-text">
                  Need more assistance?{" "}
                  <span onClick={() => navigate("/support")}>Contact us.</span>
                </p>
              </div>
            </div>
            <div className="rightone col-md-12 col-lg-6">
              <div className="image-wrapper">
                {/* <img className="right-img" src={Lady} alt="lady-img" />
                <div className="gift-content-wrapper">
                  <div className="gift-icon">
                    <img src={Union} alt="union-img" />
                  </div>
                  <div className="gift-text">
                    <p>Get reward for showing off your cool stuff</p>
                  </div>
                </div>
                <div className="dashboard-image-wrapper">
                  <img src={Dashboard} alt="dash-img" />
                </div> */}
                <img src={Grad1} alt="gradiant-lady" className="" />
              </div>
            </div>
          </div>
        </div>
        {/* Second section */}
        <div className="container second-p">
          <div className="container-wrapper">
            <h2>
              We are changing the way that products are reviewed and rated
            </h2>
          </div>
          <div className="container-wrapper">
            <p class="landing-para">Get paid by the participating brands you love.</p>
          </div>
        </div>
        {/* Third section */}
        <Cardcomponent />

        {/* Fourth section */}
        <div className="container text-center mb-4">
          <h4 className="">It is a win-win-win situation for everyone</h4>
          <Button onClick={() => navigate("/signup")} variant="primary">
            Signup
          </Button>{" "}
        </div>
        <div className="container">
          {/* Fifth section; Jumping */}
          <Jumping />
        </div>
      </div>
      <div className="transparent-privacy text-center">
        <p className="policy-statement">
          <span onClick={() => navigate("/privacy-policy")}>
            Privacy Policy
          </span>{" "}
          .{" "}
          <span onClick={() => navigate("/terms-and-conditions")}>
            Terms and Conditions
          </span>
        </p>
      </div>
    </>
  );
};

export default Landing;
