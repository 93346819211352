import { useState, createContext } from "react";
import {
  endVideoCall,
  getRecordings,
  startVideocreds,
  toggleRecording,
} from "../Services/videoService";

const VideoContext = createContext();

export const VideoProvider = ({ children }) => {
  const [videoCredentials, setVideoCredentials] = useState(null);
  const [demoId, setDemoId] = useState(null);

  const getVideoCredentials = async (id, payload) => {
    try {
      const res = await startVideocreds(id, payload);
      const data = {
        sessionId: res.data.data.meeting_details.session_id,
        token: res.data.data.meeting_details.host_token,
      };
      localStorage.setItem('ZEGO_TOKEN', res.data.data.meeting_details.host_token);
      setVideoCredentials(data);
      return res;
    } catch (error) {
      throw error;
    }
    // } else {
    //   setVideoCredentials(tokens);
    //   return tokens;
    // }
  };

  const record = async (id, data) => {
    try {
      const response = await toggleRecording(id, data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const endCall = async (id, data) => {
    try {
      const response = await endVideoCall(id, data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getVideoRecordings = async (filter) => {
    try {
      const response = await getRecordings(filter);
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  let contextData = {
    videoCredentials,
    setVideoCredentials,
    demoId,
    setDemoId,
    getVideoCredentials,
    record,
    endCall,
    getVideoRecordings,
  };
  return (
    <VideoContext.Provider value={contextData}>
      {children}
    </VideoContext.Provider>
  );
};

export default VideoContext;
