import React from "react";
import Modal from "react-bootstrap/Modal";

const RecordingModal = ({ handleClose, show }) => {
  return (
    <div className="recording-popup-modal">
      <Modal
        size={"sm"}
        show={show}
        onHide={handleClose}
        animation={false}
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{width:"100%"}}>Recording alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>The meeting is recorded</Modal.Body>
      </Modal>
    </div>
  );
};

export default RecordingModal;
