import { BlankImg, ImageLogo, ProfilePicture, VideoLogo } from "assets/images";
import CustomModal from "components/Modal/CustomModal";
import ProductModal from "components/Modal/ProductModal";
import React, { useContext } from "react";
import { useState } from "react";
import { Facebook, Instagram, Tiktok, Twitter } from "react-bootstrap-icons";
import { formatSocialHandle } from "utils/removeHandle";
import HostUserMoreDetail from "./HostUserMoreDetail";
import { Carousel } from "react-bootstrap";
import { getTimedifference } from "utils/dateFormatter";
import AuthContext from "context/AuthContext";
import { useEffectOnce } from "hooks/useEffectOnce";
import ImgComponent from "components/imgComponent";

const HostList = (props) => {
  const { user } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showUser, setShowUser] = useState(false);
  const handleCloseUser = () => setShowUser(false);
  const handleShowUser = () => setShowUser(true);
  const [showViewer, setShowViewer] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const handleCloseViewer = () => setShowViewer(false);
  const handleShowViewer = () => setShowViewer(true);
  const handleCloseVideo = () => setShowVideoModal(false);
  const handleShowVideo = () => setShowVideoModal(true);
  const [hostProfile, setHostProfile] = useState();

  const profilePicture = () => {
    let response = fetch(
      props?.hostDetails?.user?.profile_picture?.cloudinary_file
    );
    response
      .then(function (response) {
        if (response && response.status === 200) {
          setHostProfile(response.url);
        } else {
          setHostProfile(props?.hostDetails?.user?.profile_picture?.file);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffectOnce(() => {
    profilePicture();
  }, []);

  const getDetails = () => {
    if (props?.hostDetails.accept_status === "ACTIVE") {
      return (
        <>
          <button onClick={handleShow} className="btn button-view-host-status">
            Approved &#10003;
          </button>
          <ProductModal
            showStatus={show}
            clickStatus={handleClose}
            title="Review Detail"
            hostInfo={props?.hostDetails}
            buttonOption="Accept"
            customClass="product-review-modal"
            buttonView={true}
          />
        </>
      );
    } else if (props?.hostDetails.accept_status === "DECLINED") {
      return (
        <>
          <button onClick={handleShow} className="btn button-view-host-status">
            Pending &#x2B55;
          </button>
          <ProductModal
            showStatus={show}
            clickStatus={handleClose}
            buttonOption="Decline"
            title="Review Detail"
            hostInfo={props?.hostDetails}
            customClass="product-review-modal"
            buttonView={true}
          />
        </>
      );
    } else if (props?.hostDetails.accept_status === "PENDING") {
      return (
        <>
          <button
            onClick={handleShow}
            className="btn button-view-host"
            disabled={user.role === 5}
          >
            Show Responses
          </button>
          <ProductModal
            showStatus={show}
            clickStatus={handleClose}
            buttonOption="NotSure"
            title="Review Detail"
            hostInfo={props?.hostDetails}
            customClass="product-review-modal"
            buttonView={true}
          />
        </>
      );
    } else if (props?.hostDetails.accept_status === "ADMIN_REJECTED") {
      return (
        <>
          <button className="btn button-view-host-status">
            Rejected &#10060;
          </button>
        </>
      );
    }
  };

  const getMedia = () => {
    return (
      <>
        {props?.hostDetails?.media_count?.picture_count >= 1 ? (
          <>
            <img
              src={ImageLogo}
              alt="uploaded"
              className="uploaded-media-icon"
              onClick={handleShowViewer}
            />
            <CustomModal
              showStatus={showViewer}
              clickStatus={handleCloseViewer}
              buttonMessage="Close"
              closeStatus={handleCloseViewer}
              title="Photo"
              message={
                <div className="good-bad-photo-viewer">
                  <Carousel variant="dark">
                    {(props?.hostDetails?.media).map((image, i) => {
                      if (image.picture) {
                        return (
                          <Carousel.Item
                            interval={20000}
                            key={i}
                            className="custom-carousel"
                          >
                            <img
                              key={i}
                              // style={{
                              //   height: "5rem",
                              //   width: "auto",
                              // }}
                              className="d-block w-100"
                              src={image.picture.file}
                              alt="slide"
                            />
                          </Carousel.Item>
                        );
                      }
                    })}
                  </Carousel>
                </div>
              }
              secondMessage="none"
              secondButton={false}
            />
          </>
        ) : null}{" "}
        {props?.hostDetails.media_count.video_count >= 1 ? (
          <>
            <img
              src={VideoLogo}
              alt="uploaded"
              className="uploaded-media-icon"
              onClick={handleShowVideo}
            />{" "}
            <CustomModal
              showStatus={showVideoModal}
              clickStatus={handleCloseVideo}
              buttonMessage="Close"
              closeStatus={handleCloseVideo}
              title="Video"
              message={
                <div className="good-bad-photo-viewer">
                  <Carousel>
                    {props?.hostDetails?.media.map((media, i) => {
                      if (media.video) {
                        return (
                          <Carousel.Item
                            interval={200000}
                            key={i}
                            className="custom-carousel"
                          >
                            <video
                              key={i}
                              playsInline
                              autoPlay={false}
                              controls
                              // muted
                              loop
                              className="video-player_product"
                            >
                              <source
                                key={i}
                                src={media.video.file}
                                type="video/mp4"
                              />
                            </video>
                          </Carousel.Item>
                        );
                      }
                    })}
                  </Carousel>
                </div>
              }
              secondMessage="none"
              secondButton={false}
            />
          </>
        ) : null}
      </>
    );
  };
  return (
    <tr>
      <td className="image-title-wrapper" onClick={handleShowUser}>
        <ImgComponent
          className="host-pp-small"
          src={
            props?.hostDetails?.user?.profile_picture?.file === null
              ? BlankImg
              : hostProfile
          }
          alt="user"
        />{" "}
        <span>
          {props?.hostDetails?.user.first_name}{" "}
          {(props?.hostDetails?.user.last_name).charAt(0)}.
        </span>
      </td>
      <CustomModal
        showStatus={showUser}
        modalClass="profile_detail"
        clickStatus={handleCloseUser}
        buttonMessage="Close"
        closeStatus={handleCloseUser}
        title=" "
        message={<HostUserMoreDetail details={props?.hostDetails?.user.id} />}
        secondMessage="none"
        secondButton={false}
      />
      <td>{getTimedifference(props.hostDetails.created_at)}</td>
      <td>{getDetails()}</td>
      <td>{props?.hostDetails?.product.name}</td>
      <td className="text-center">
        {props.hostDetails.modified_at ? <>&#10004;</> : <>&#10060;</>}
      </td>
      <td>
        {(user.role === 2 ||
          user.role === 4 ||
          user.role === 5 ||
          user.role === 6) &&
          getMedia()}
      </td>
      {/* <td>aa</td> */}
      <td>
        {props?.hostDetails?.user?.user_social?.instagram_handle !== "" ? (
          <a
            target={"_blank"}
            rel="noreferrer"
            href={`https://www.instagram.com/${formatSocialHandle(
              props?.hostDetails?.user?.user_social?.instagram_handle
            )}`}
          >
            <Instagram />
          </a>
        ) : (
          <></>
        )}{" "}
        {props?.hostDetails?.user?.user_social?.tiktok_handle !== "" ? (
          <a
            target={"_blank"}
            rel="noreferrer"
            href={`https://www.tiktok.com/@${formatSocialHandle(
              props?.hostDetails?.user?.user_social?.tiktok_handle
            )}`}
          >
            <Tiktok />
          </a>
        ) : (
          <></>
        )}{" "}
        {props?.hostDetails?.user?.user_social?.twitter_handle !== "" ? (
          <a
            target={"_blank"}
            rel="noreferrer"
            href={`https://twitter.com/${formatSocialHandle(
              props?.hostDetails?.user?.user_social?.twitter_handle
            )}`}
          >
            <Twitter />
          </a>
        ) : (
          <></>
        )}{" "}
        {props?.hostDetails?.user?.user_social?.facebook_handle !== "" ? (
          <a
            target={"_blank"}
            rel="noreferrer"
            href={`https://www.facebook.com/${formatSocialHandle(
              props?.hostDetails?.user?.user_social?.facebook_handle
            )}`}
          >
            <Facebook />
          </a>
        ) : (
          <></>
        )}
      </td>
    </tr>
  );
};

export default React.memo(HostList);
