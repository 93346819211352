import axios from 'axios';
import axiosInstance from './axios';
import * as LocalStorageService from '../utils/localStorageService';

const deviceId = LocalStorageService.getItem('deviceId');

export const userDemoRequest = async () =>
  axiosInstance.get(`/api/v1/demo-list/?order=true`);

export const userDemoAccepted = async () =>
  axiosInstance.get(`/api/v1/demo-accepted/?order=true`);

export const userDemoAcceptedStore = async (param) =>
  axiosInstance.get(`/api/v1/demo-accepted-store/${param}`);

export const userDemoPendingStore = async () =>
  axiosInstance.get(`/api/v1/demo-pending-store/?order=true`);

export const userAcceptDemo = async (data, params) =>
  axiosInstance.put(`/api/v1/demo-schedule/${params}/`, data);

export const userRejectDemo = async (data, params) =>
  axiosInstance.put(`/api/v1/demo-decline/${params}/`, data);

export const rejectDemoRequestStore = async (data, params) =>
  axiosInstance.put(`/api/v1/product-approve/${params}/`, data);

export const rejectDemoRequestAdmin = async (data, params) =>
  axiosInstance.put(`/api/v1/admin-product-review/${params}/`, data);

export const deleteReviewRequest = async (params) =>
  axiosInstance.delete(`/api/v1/product-review/${params}/`);

export const requestForReviewByHost = async (data) =>
  axiosInstance.post(`/api/v1/product-review/`, data);

export const editReviewByHostPut = async (id, data) =>
  axiosInstance.put(`/api/v1/product-review/${id}/`, data);

export const hostSeeRequest = async (params) =>
  axiosInstance.get(`/api/v1/product-review/${params}`);

export const hostProductReviewEdit = async (id) =>
  axiosInstance.get(`/api/v1/product-review/${id}/`);

export const hostSeeRequestOnce = async (params) =>
  axiosInstance.get(`/api/v1/product-review-unique/${params}`);

export const hostSeeRequestAdmin = async (params) =>
  axiosInstance.get(`/api/v1/admin-product-review/${params}`);

export const hostCancelAcceptedDemo = async (data, params) =>
  axiosInstance.put(`/api/v1/demo-cancel/${params}/`, data);

export const hostUserRescheduleDemo = async (data, params) =>
  axiosInstance.put(`/api/v1/demo-reschedule/${params}/`, data);

export const guestRescheduleDemo = async (data, params) =>
  axios.put(
    `${process.env.REACT_APP_BASE_URL}/api/v1/demo-reschedule/${params}/`,
    data
  );

export const getPBAdminHostData = async (params) =>
  axiosInstance.get(`/api/v1/purebrand-host-list/${params}`);

export const getPBAdminCompanyData = async (params) =>
  axiosInstance.get(`/api/v1/purebrand-company-list/${params}`);

export const companyFullData = async (param) =>
  axiosInstance.get(`/api/v1/purebrand-company-get/${param}/`);

export const deleteMediaOnceHost = async (param) =>
  axiosInstance.delete(`/api/v1/product-media/${param}`);

export const uploadNewEditedMedia = async (data) =>
  axiosInstance.post(`/api/v1/product-media/`, data);

export const getExistingSoftReview = async (params) =>
  axiosInstance.get(`/api/v1/soft-review-history/${params}`);

export const getDemoSatusPublic = async (params) =>
  axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/demo-status/${params}/`);
// /demo-accepted-user-store/

/**
 * Makes an API call to retrieve the authorization status of a host in the demo.
 *
 * @param {number} params - The ID or unique identifier for the demo.
 * @returns {Promise<object>} - A promise that resolves to the response object containing the demo authorization status data.
 *
 * @example
 * getDemoStatusAuthenticated(123)
 *   .then(response => console.log(response.data))
 *   .catch(error => console.error(error));
 */
export const getDemoStatusAuthenticated = async (params) =>
  axiosInstance.get(`/api/v1/demo-status/${params}/`);

export const getHostOtherAccepted = async (params) =>
  axiosInstance.get(`/api/v1/demo-accepted-user-store/${params}`);

export const demoGuestPresence = async (param, data) =>
  axios.put(
    `${process.env.REACT_APP_BASE_URL}/api/v1/demo-guest-presence/${param}/`,
    data
  );

export const demoHostPresence = async (param, data) => {
  const response = await axios({
    method: 'put',
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/demo-host-presence/${param}/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + LocalStorageService.getAccessToken(),
      deviceid: deviceId,
      devicetype: 'web',
    },
    data: data,
  });
  return response;
};

/**
 * This function is used to make a api call for the authorization of a host in the demo.
 *
 * @param {object} data to be passed in the body
 * @returns {object} Response object with the data
 */

export const authorizeHostForDemo = async (data) => {
  return await axiosInstance({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/demo-authorize-host/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + LocalStorageService.getAccessToken(),
      deviceid: deviceId,
      devicetype: 'web',
    },
    data,
  });
};
