import { BlankImg } from "assets/images";
import ImgComponent from "components/imgComponent";
import { useEffectOnce } from "hooks/useEffectOnce";
import React, { useState } from "react";

const LogbookAllList = (logbook) => {
  const [hostProfile, setHostProfile] = useState();

  const profilePicture = () => {
    let response = fetch(logbook?.log?.host?.profile_picture?.cloudinary_file);
    response
      .then(function (response) {
        if (response && response.status === 200) {
          setHostProfile(response?.url);
        } else {
          setHostProfile(logbook?.log?.host?.profile_picture?.file);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffectOnce(() => {
    profilePicture();
  }, []);

  return (
    <>
      <tr>
        <td className="image-title-wrapper">
          <ImgComponent
            className="host-pp-small"
            src={
              logbook?.log?.host?.profile_picture?.file !== null
                ? hostProfile
                : BlankImg
            }
            alt="user"
          />
          <span>
            {logbook?.log?.host?.first_name}{" "}
            {(logbook?.log?.host?.last_name).charAt(0)}.
          </span>
        </td>
        <td> {logbook?.log?.text}</td>
        <td> {logbook?.log?.created_at.split("T")[0]} </td>
        <td>
          {logbook?.log?.author?.first_name} {logbook?.log?.author?.last_name}
        </td>
      </tr>
    </>
  );
};

export default LogbookAllList;
