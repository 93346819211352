import { BlankImg } from "assets/images";
import ImgComponent from "components/imgComponent";
import { useEffectOnce } from "hooks/useEffectOnce";
import React, { useState } from "react";

const StoreDashboardListShows = (props) => {
  const [hostProfile, setHostProfile] = useState();

  const profilePicture = () => {
    let response = fetch(
      props?.hostDetails?.user?.profile_picture?.cloudinary_file
    );
    response
      .then(function (response) {
        if (response && response.status === 200) {
          setHostProfile(response?.url);
        } else {
          setHostProfile(props?.hostDetails?.user?.profile_picture?.file);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffectOnce(() => {
    profilePicture();
  }, []);

  return (
    <tr>
      <td className="image-title-wrapper">
        <ImgComponent
          className="host-pp-small"
          src={
            props?.hostDetails?.user?.profile_picture?.file === null
              ? BlankImg
              : hostProfile
          }
          alt="user"
        />
        <span>
          {props?.hostDetails?.user.first_name}{" "}
          {(props?.hostDetails?.user.last_name).charAt(0)}.
        </span>
      </td>
      <td>
        ${" "}
        {props?.hostDetails?.revenue !== null ? props?.hostDetails?.revenue : 0}
      </td>
      <td>
        ${" "}
        {props?.hostDetails?.earning !== null ? props?.hostDetails?.earning : 0}
      </td>
      <td>{props?.hostDetails?.shows}</td>
      <td>{props?.hostDetails?.top_product}</td>
    </tr>
  );
};

export default React.memo(StoreDashboardListShows);
