import CustomModal from "components/Modal/CustomModal";
import AuthContext from "context/AuthContext";
import DataContext from "context/DataContext";
import React, { useContext, useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import AddTag from "./AddTag";
import TagTable from "./TagTable";

const TagList = () => {
  const {
    productListAllTag,
    productListAllTagPrev,
    productListAllTagNext,
    storeAdminProductListTag,
  } = useContext(DataContext);
  const { user } = useContext(AuthContext);
  const [loadingPros, setLoadingPros] = useState(false);
  const [showAddTag, setShowAddTag] = useState(false);
  const handleClose = () => {
    setShowAddTag(false);
  };

  const getListAll = async () => {
    setLoadingPros(true);
    try {
      await storeAdminProductListTag(`?order=true`);
      setLoadingPros(false);
    } catch (error) {
      setLoadingPros(true);
    }
  };

  useEffect(() => {
    getListAll();
  }, []);

  const getList = () => {
    if (loadingPros) {
      return (
        <tr>
          <td>
            <div className="d-flex justify-content-center">
              <Spinner animation="grow" />
              <Spinner animation="grow" />
              <Spinner animation="grow" />
            </div>
          </td>
        </tr>
      );
    } else {
      if (productListAllTag) {
        if (productListAllTag?.length < 1) {
          return (
            <tr>
              <td>There aren't any category tags yet!</td>
            </tr>
          );
        } else {
          return productListAllTag?.map((productListTag, index) => {
            return <TagTable key={index} tags={productListTag} />;
          });
        }
      } else {
        return <Spinner animation="grow" />;
      }
    }
  };

  return (
    <>
      <div className="text-end">
        <div
          className=" btn btn-primary bg-secondary m-3 "
          onClick={() => {
            setShowAddTag(true);
          }}
          hidden={user.role === 5}
        >
          + Add Category
        </div>
      </div>
      <CustomModal
        showStatus={showAddTag}
        clickStatus={handleClose}
        buttonMessage="Close"
        closeStatus={handleClose}
        title="Add Category"
        message={<AddTag />}
        secondButton={false}
        secondMessage="none"
      />
      <div className="card cardc">
        <div className="m-3">
          <Table className="table-store" responsive>
            <thead>
              <tr>
                <th scope="col">Tags</th>
                {user.role !== 5 && <th colSpan={2}>Action</th>}
              </tr>
            </thead>
            <tbody>{getList()}</tbody>
          </Table>

          {/* TODO: Need to uncomment this once the pagination for this categories is completed */}

          {/* {(productListAllTagNext !== null ||
            productListAllTagPrev !== null) && (
            <div className="button-wrapper-product text-center my-4">
              <button
                onClick={() => {
                  setLoadingPros(true);
                  let res = storeAdminProductListTag(
                    new URL(productListAllTagPrev).search
                  );
                  res.then(() => setLoadingPros(false));
                }}
                className="btn btn-outline-primary previous-button"
                disabled={productListAllTagPrev === null}
              >
                Prev
              </button>
              <button
                onClick={() => {
                  setLoadingPros(true);
                  let res = storeAdminProductListTag(
                    new URL(productListAllTagNext).search
                  );
                  res.then(() => setLoadingPros(false));
                }}
                className="btn btn-outline-primary previous-button"
                disabled={productListAllTagNext === null}
              >
                Next
              </button>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default TagList;
