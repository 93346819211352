import AuthContext from "context/AuthContext";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Loginimage from "Pages/Auth/login/Host/Loginimage";
import React, { useContext, useState } from "react";
import { Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import "../Auth/login/Host/login.css";
import "./support.css";

const supportFormScheme = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address format")
    .required("Email is required"),
  name: Yup.string().required("Name is required"),
  message: Yup.mixed().required("Message is required"),
  subject: Yup.string().required("Subject is required"),
});
const ContactSupport = () => {
  return (
    <div className="container mt-5 login-wrapper">
        <SupportFormComponent />
    </div>
  );
};

export default ContactSupport;

export const SupportFormComponent = () => {
  const { sendSupportMail } = useContext(AuthContext);
  const [enabled, setEnabled] = useState(true);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  return (
    <div className="col col-lg-12 col-12 col-md-12 col-sm-12 ">
      {sent ? (
        <div className="d-flex justify-content-center text-center">
          <div className="successfully-sent-wrapper">
            <p>
              Your message has been sent! <br />
              We will contact you soon through email.
              <br />
              Meanwhile, You can continue using purebrand.
            </p>
            <div className="button-wrapper">
              <button className="btn btn-primary" onClick={() => navigate("/")}>
                Go Home
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center text-center setting-contact-form-wrapper">
          <Formik
            initialValues={{
              name: "",
              email: "",
              message: "",
              subject: "",
            }}
            onSubmit={(data) => {
              setEnabled(false);

              const loginRresponse = sendSupportMail(data);
              loginRresponse
                .then(function (response) {
                  setEnabled(true);
                  setSent(true);
                })
                .catch(function (error) {
                  setEnabled(true);
                  setError("Some error occured. Please try again.");
                });
            }}
            validationSchema={supportFormScheme}
          >
            {({ values, touched, errors }) => (
              <Form className="contact-form">
                <h5 className="title">Contact us</h5>
                <p className="description">
                  Feel free to contact us if you need any assistance, any help
                  or another question.
                </p>
                {error ? <Alert>{error}</Alert> : <></>}
                <div className="mb-3">
                  <Field
                    type="text"
                    name="name"
                    placeholder="Full name"
                    className={`form-control rounded border-white form-input ${
                      touched.name && errors.name ? "is-invalid" : ""
                    }`}
                  />
                  <ErrorMessage
                    component="div"
                    name="name"
                    className="invalid-feedback"
                  />
                </div>
                <div className="mb-3">
                  <Field
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    className={`form-control rounded border-white form-input ${
                      touched.email && errors.email ? "is-invalid" : ""
                    }`}
                  />
                  <ErrorMessage
                    component="div"
                    name="email"
                    className="invalid-feedback"
                  />
                </div>
                <div className="mb-3">
                  <Field
                    as="select"
                    name="subject"
                    className={`form-select rounded border-white form-input ${
                      touched.subject && errors.subject ? "is-invalid" : ""
                    }`}
                  >
                    <option value="">Subject</option>
                    <option value={"General support"}>General</option>
                    <option value={"Accounting support"}>Accounting</option>
                    <option value={"System Support"}>System</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    name="subject"
                    className="invalid-feedback"
                  />
                </div>
                <div className="mb-3">
                  <Field
                    as="textarea"
                    rows={5}
                    name="message"
                    placeholder="Your message here"
                    className={`form-control rounded border-white  form-text-area ${
                      touched.message && errors.message ? "is-invalid" : ""
                    }`}
                  />
                  <ErrorMessage
                    component="div"
                    name="message"
                    className="invalid-feedback"
                  />
                </div>
                <div className="submit-button-wrapper">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={!enabled}
                  >
                    Send
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};
