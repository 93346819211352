import { BlankImg, ProfilePicture } from "assets/images";
import CustomModal from "components/Modal/CustomModal";
import { useEffectOnce } from "hooks/useEffectOnce";
import React, { useState } from "react";
import CompanyDetail from "../Companies/CompanyDetail";
import "./admin.css";
import ImgComponent from "components/imgComponent";

const CustomListAdmin = (props) => {
  const [hostProfile, setHostProfile] = useState();
  const [companyProfile, setCompanyProfile] = useState();
  const profilePicture = () => {
    let response = fetch(
      props?.hostDetails?.host?.profile_picture?.cloudinary_file
    );
    response
      .then(function (response) {
        if (response && response.status === 200) {
          setHostProfile(response?.url);
        } else {
          setHostProfile(props.hostDetails.host.profile_picture.file);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    let companyResponse = fetch(
      props?.hostDetails?.company.profile_picture.cloudinary_file
    );
    companyResponse
      .then(function (companyResponse) {
        if (companyResponse && companyResponse.status === 200) {
          setCompanyProfile(companyResponse?.url);
        } else {
          setCompanyProfile(props?.hostDetails?.company.profile_picture.file);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffectOnce(() => {
    profilePicture();
  }, []);

  return (
    <tr>
      <td className="image-title-wrapper">
        <ImgComponent
          className="host-pp-small"
          src={
            props.hostDetails.host.profile_picture.file === null
              ? BlankImg
              : hostProfile
          }
          alt="user"
        />
        <span>
          {props?.hostDetails?.host.first_name}{" "}
          {(props?.hostDetails?.host.last_name).charAt(0)}.
        </span>
      </td>
      <td>${props?.hostDetails?.revenue}</td>
      <td>${props?.hostDetails?.earning}</td>
      <td className="image-title-wrapper">
        <ImgComponent
          className="host-pp-small"
          src={
            props?.hostDetails?.company.profile_picture.file === null
              ? ProfilePicture
              : companyProfile
          }
          alt="company"
        />{" "}
        {props?.hostDetails?.company.store.name}
      </td>
      <td>{props?.hostDetails?.top_product}</td>
    </tr>
  );
};

export default CustomListAdmin;

export const CustomListAdminPage = (props) => {
  const [hostProfile, setHostProfile] = useState();
  const profilePicture = () => {
    let response = fetch(
      props?.hostDetails?.host?.profile_picture?.cloudinary_file
    );
    response
      .then(function (response) {
        if (response && response.status === 200) {
          setHostProfile(response?.url);
        } else {
          setHostProfile(props.hostDetails.host.profile_picture.file);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffectOnce(() => {
    profilePicture();
  }, []);

  return (
    <tr>
      <td className="image-title-wrapper">
        <ImgComponent
          className="host-pp-small"
          src={
            props.hostDetails.host.profile_picture.file === null
              ? BlankImg
              : hostProfile
          }
          alt="user"
        />{" "}
        <span>
          {props?.hostDetails?.host.first_name}{" "}
          {(props?.hostDetails?.host.last_name).charAt(0)}.
        </span>
      </td>
      <td>${props?.hostDetails?.revenue}</td>
      <td>${props?.hostDetails?.earning}</td>
      <td>{props?.hostDetails?.shows}</td>
      <td>{props?.hostDetails?.top_product}</td>
    </tr>
  );
};

export const CustomCompaniesListAdmin = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [companyProfile, setCompanyProfile] = useState();

  const profilePicture = () => {
    let companyResponse = fetch(
      props?.hostDetails?.company.profile_picture.cloudinary_file
    );
    companyResponse
      .then(function (companyResponse) {
        if (companyResponse && companyResponse.status === 200) {
          setCompanyProfile(companyResponse?.url);
        } else {
          setCompanyProfile(props?.hostDetails?.company.profile_picture.file);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffectOnce(() => {
    profilePicture();
  }, []);

  return (
    <>
      <tr className="row-company-table" onClick={handleShow}>
        <td className="image-title-wrapper">
          <ImgComponent
            className="host-pp-small"
            src={
              props?.hostDetails?.company.profile_picture.file === null
                ? ProfilePicture
                : companyProfile
            }
            alt="user"
          />{" "}
          <span>{props?.hostDetails?.company.store.name} </span>
        </td>
        <td>{props?.hostDetails?.hosts}</td>
        <td>{props?.hostDetails?.shows}</td>
        <td>${props?.hostDetails?.revenue}</td>
        <td>{props?.hostDetails?.top_product}</td>
      </tr>
      <CustomModal
        showStatus={show}
        clickStatus={handleClose}
        buttonMessage="Close"
        closeStatus={handleClose}
        title="Company Detail"
        message={
          <CompanyDetail identifier={props?.hostDetails.company.store.detail} />
        }
        secondMessage="none"
        secondButton={false}
      />
    </>
  );
};
