import React, { useEffect, useState } from 'react';
import './Transcript.css';

const Transcript = ({ s3Url, meetingParticipants, host, guest }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  /**
   * This function is used to get the name of the speaker
   *
   * @param {object} speech object consisting speaker and text
   * @returns {text} speaker text
   */

  const getSpeakerName = (speech) => {
    if (meetingParticipants?.host === speech?.speaker) {
      return host?.first_name;
    } else if (meetingParticipants?.guest === speech?.speaker) {
      return guest?.full_name;
    } else {
      return speech?.speaker;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(s3Url, {
          method: 'GET',
          mode: 'cors', // Ensure mode is set to 'cors'
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const jsonData = await response.json();
        setData(jsonData);
      } catch (err) {
        setError(err?.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [s3Url]);

  if (!s3Url) {
    return <p>No transcript available</p>;
  }

  if (loading) {
    return <p>Loading..</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div>
      {data?.map((speech) => (
        <div className="transcript-container">
          <span className="transcript-speaker-header">
            {getSpeakerName(speech)} :{' '}
          </span>{' '}
          <span className="transcript-speaker-text">{speech?.text}</span>
        </div>
      ))}
    </div>
  );
};

export default Transcript;
