import React, { useState } from "react";
const ImgComponent = (props) => {
  const [style, setStyle] = useState({});
  const {className, src, ...rest} = props;

  return (
    <img
      className={className}
      src={src}
      style={style}
      onError={(e) => {
        console.log("Image load failed, applying fallback:", e.target.src);
        e.target.onerror = null; // Prevent infinite loop
        e.target.src =
          "https://purebrand-storage.s3.us-east-2.amazonaws.com/logo512.png";
        setStyle({
          backgroundColor: "#f0f0f0",
          padding: "5px",
          paddingLeft: "10px",
          objectFit: "cover",
        });
      }}
      alt=""
      {...rest}
    />
  );
};

export default ImgComponent;
